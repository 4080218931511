import { useEffect, useRef } from 'react';

import { AnimatePresence, AnimationProps } from 'framer-motion';
import { useRouter } from 'next/router';
import { Portal } from 'react-portal';
import { useClickAway, useKey } from 'react-use';

import { useLockBodyScroll } from '@hultafors/shared/hooks';

import { SlideOutHeaderElement } from '../slide-out-header/slide-out-header';

import { Backdrop, Body, Inner, SlideOutPosition } from './slide-out.styled';
export { type SlideOutPosition } from './slide-out.styled';

interface SlideOutProps {
  children?: React.ReactNode;
  position?: SlideOutPosition;
  container?: Element | null | string;
  isOpen: boolean;
  toggle(force: boolean): void;
  header: SlideOutHeaderElement;
  locked?: boolean;
}

/**
 * Slide out component for menus and the like
 * @param {SlideOutProps} props - Component props
 * @returns {JSX.Element} - React element
 */
export const SlideOut: React.FC<SlideOutProps> = ({
  children,
  position = 'inline-end',
  container = '#portal',
  isOpen,
  toggle,
  header,
  locked,
}) => {
  const nodeRef = useRef<Element | null | undefined>(null);
  const innerRef = useRef<HTMLElement>(null);
  const router = useRouter();
  // Lock scroll on body while open
  useLockBodyScroll(isOpen);
  // Close on escape
  useKey('Escape', (event) => {
    event.preventDefault();
    if (!locked) {
      toggle(false);
    }
  });
  // Close on click outside
  useClickAway(innerRef, (event) => {
    event.preventDefault();
    if (!locked) {
      toggle(false);
    }
  });

  function close() {
    toggle(false);
  }

  // Close on route change
  useEffect(() => {
    router.events.on('routeChangeComplete', close);
    return () => {
      router.events.off('routeChangeComplete', close);
    };
  }, [router.events]);

  // Attach to container on mount
  useEffect(() => {
    if (container && typeof container !== 'string') {
      nodeRef.current = container;
    }
    if (typeof container === 'string') {
      nodeRef.current = document.querySelector(container);
    }
  }, []);

  // Duration of transitions
  const duration = 0.3;

  // Get initial transform based on position
  let initialTransform = 'translateX(100%)';
  if (position === 'block-start') {
    initialTransform = 'translateY(-100%)';
  }
  if (position === 'block-end') {
    initialTransform = 'translateY(100%)';
  }
  if (position === 'inline-start') {
    initialTransform = 'translateX(-100%)';
  }

  // Animation props for backdrop
  const backdrop: AnimationProps = {
    animate: {
      WebkitBackdropFilter: 'blur(3px) brightness(50%)',
      backdropFilter: 'blur(3px) brightness(50%)',
      transition: { duration, ease: 'linear' },
    },
    exit: {
      WebkitBackdropFilter: 'blur(0px) brightness(100%)',
      backdropFilter: 'blur(0px) brightness(100%)',
      transition: { duration, ease: 'linear' },
    },
    initial: {
      WebkitBackdropFilter: 'blur(0px) brightness(100%)',
      backdropFilter: 'blur(0px) brightness(100%)',
    },
  };

  // Animation props for inner content
  const inner: AnimationProps = {
    animate: {
      transform: 'translateX(0%) translateY(0%)',
      transition: { duration, ease: 'easeOut' },
    },
    exit: {
      transform: initialTransform,
      transition: { duration, ease: 'easeIn' },
    },
    initial: { transform: initialTransform },
  };

  // If no container, return null
  if (!nodeRef.current) {
    return null;
  }

  return (
    <Portal node={nodeRef.current}>
      <AnimatePresence>
        {isOpen && (
          <Backdrop
            initial={backdrop.initial}
            animate={backdrop.animate}
            exit={backdrop.exit}
          >
            <Inner
              initial={inner.initial}
              animate={inner.animate}
              exit={inner.exit}
              ref={innerRef}
              $position={position}
            >
              {header}
              <Body>{children}</Body>
            </Inner>
          </Backdrop>
        )}
      </AnimatePresence>
    </Portal>
  );
};
