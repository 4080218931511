import { HTMLAttributes } from 'react';

import { CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  GridColumnGapProp,
  GridColumnsProp,
  GridRowGapProp,
  GridStyled,
} from './grid.styled';

export interface GridProps {
  children?: React.ReactNode;
  className?: string;
  style?: HTMLAttributes<HTMLDivElement>['style'];
  align?: CSSProperties['alignItems'];
  margin?: CSSProperties['margin'];
  maxWidth?: CSSProperties['maxWidth'];
  center?: boolean;
  columnGap?: GridColumnGapProp;
  columns?: GridColumnsProp;
  minColumnWidth?: string;
  rowGap?: GridRowGapProp;
  autoFlow?: CSSProperties['gridAutoFlow'];
}

export const gridSpacing = {
  huge: '6.25em',
  large: '4.375em',
  medium: '3.125em',
  regular: '1.5rem',
  small: '1.5em',
  tinier: '0.5em',
  tiny: '0.75em',
  xSmall: '1em',
  xxSmall: '0.25em',
};

export type GridSpacing = keyof typeof gridSpacing;

export const Grid: React.FC<GridProps> = ({
  children,
  align = 'stretch',
  columnGap = [{ columnGap: 40 }, { breakpoint: 'mobileMax', columnGap: 20 }],
  margin = '0 auto',
  columns = [{ columns: 4 }, { breakpoint: 'mobileMax', columns: 12 }],
  maxWidth = `${breakpoints.maxPageWidth}`,
  minColumnWidth,
  rowGap = 0,
  className,
  style,
}) => {
  return (
    <GridStyled
      $align={align}
      $columnGap={columnGap}
      $columns={columns}
      $margin={margin}
      $maxWidth={maxWidth}
      $minColumnWidth={minColumnWidth}
      $rowGap={rowGap}
      className={className}
      style={style}
    >
      {children}
    </GridStyled>
  );
};
