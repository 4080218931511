import { StyledSkeleton } from './skeleton.styled';

interface SkeletonProps {
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];
  style?: React.CSSProperties;
  tagName?: 'div' | 'span';
  className?: string;
}
export const Skeleton: React.FC<SkeletonProps> = ({
  width,
  height,
  style,
  tagName = 'div',
  className,
}) => {
  return (
    <StyledSkeleton
      as={tagName}
      style={{
        ...style,
        height: height || style?.height,
        width: width || style?.width,
      }}
      className={className}
    />
  );
};
