import { useEffect, useRef } from 'react';

import Script from 'next/script';

import { Grid } from '../grid/grid';
import { GridChild } from '../grid-child/grid-child';

import { StyledAzureVideo, VideoContainer } from './azure-video.styled';

interface AzureVideoProps {
  src: string;
  thumbnail?: string;
  className?: string;
  controls?: boolean;
}

export const AzureVideo: React.FC<AzureVideoProps> = ({
  src,
  thumbnail = '',
  className,
  controls,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  function initializeAmp() {
    if (!videoRef.current) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const myPlayer = amp(videoRef?.current, {
      autoplay: false,

      controls: true,

      customPlayerSettings: { nativeControlsForTouch: 'false' },

      fluid: true,
      /* Options */
      techOrder: [
        'azureHtml5JS',
        'flashSS',
        'html5FairPlayHLS',
        'silverlightSS',
        'html5',
      ],
    });
    myPlayer.src([
      {
        src: `${src}`,
        type: 'application/vnd.ms-sstr+xml',
      },
    ]);
  }

  // Extremely janky hack to defer loading of css
  useEffect(() => {
    if (typeof document !== 'undefined') {
      const href =
        '//amp.azure.net/libs/amp/2.3.11/skins/amp-flush/azuremediaplayer.min.css';

      if (!document.querySelector(`link[href="${href}"]`)) {
        const node = document.createElement('link');
        node.href = href;
        node.rel = 'stylesheet';
        document.head.appendChild(node);
      }
    }
  }, []);

  return (
    <StyledAzureVideo className={className}>
      <Script
        src="//amp.azure.net/libs/amp/2.3.11/azuremediaplayer.min.js"
        onLoad={initializeAmp}
        onReady={initializeAmp}
      />
      <Grid>
        <GridChild>
          <VideoContainer>
            <video
              preload="true"
              ref={videoRef}
              className="azuremediaplayer amp-flush-skin"
              poster={thumbnail}
              controls={controls}
            ></video>
          </VideoContainer>
        </GridChild>
      </Grid>
    </StyledAzureVideo>
  );
};
